import EventEmitter from "eventemitter3";

export class User {
    name?: string;
    email: string;
    avatar?: string;

    constructor(email: string, name?: string, avatar?: string) {
        this.name = name || email.split('@')[0];
        this.email = email;
        this.avatar = avatar;
    }
}

export interface Auth {
    authenticate(email: string, password: string, newAccount: boolean): Promise<User>
    googleLogin(): Promise<User>
    signOut(): Promise<void>
    events(): EventEmitter;
}

export enum AuthErrorReason {
    INVALID_CREDENTIALS,
    EMAIL_EXISTS,
}

export class AuthError extends Error {
    reason: AuthErrorReason

    constructor(reason: AuthErrorReason) {
        super();
        this.reason = reason;
    }
}
