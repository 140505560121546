import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import CreateDeck from "@/views/CreateDeck.vue";
import Authenticate from "@/views/Authenticate.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "decks" */ '../views/DeckList.vue')
  },
  {
    path: '/decks',
    name: 'decks',
    component: () => import(/* webpackChunkName: "decks" */ '../views/DeckList.vue')
  },
  {
    path: '/decks/:deckId',
    component: () => import(/* webpackChunkName: "decks" */ '../views/DeckView.vue'),
    props: true
  },
  {
    path: '/decks/:deckId/c/:conversationId',
    name: 'conversation',
    component: () => import(/* webpackChunkName: "decks" */ '../views/ConversationView.vue'),
    props: true
  },
  {
    path: '/decks/:deckId/new-conversation',
    name: 'new-conversation',
    component: () => import(/* webpackChunkName: "decks" */ '../views/NewConversation.vue'),
    props: true
  },
  {
    path: '/decks/:deckId/:mode/:conversationId',
    name: 'session',
    // component: () => import(/* webpackChunkName: "decks" */ '../components/session/Session.vue'),
    props: {
      session: true
    },
    components: {
      session: () => import(/* webpackChunkName: "decks" */ '../components/session/Session.vue')
    },
    meta: {
      inSession: true
    }
  },
  {
    path: '/create-deck',
    name: 'create-deck',
    component: CreateDeck
  },
  {
    path: '/login',
    name: 'login',
    component: Authenticate,
    meta: {
      noAuth: true,
      guest: true
    }
  },
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.BASE_URL,
  routes
})

export default router
