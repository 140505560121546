import EventEmitter from "eventemitter3";

export interface Database {
    events(): EventEmitter;
    onInit(): void;

    close(): void;
}

export enum DatabaseEvents {
    INSERT = "db-insert",
    UPDATE = "db-update",
    DELETE = "db-delete"
}

export enum DatabaseModels {
    DECKS = "decks",
    CONVERSATIONS = "conversations"
}
