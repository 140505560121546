
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Deck} from "@/data/model/Deck";
import {mapState} from "vuex";
import {Conversation} from "@/data/model/Conversation";
import {Prompt} from "@/data/model/Prompt";
import StudyModesList from "@/components/StudyModesList.vue";
import {AudioManager} from "@/services/AudioManager";
import {SessionPrompt} from "@/data/model/Session";

@Component
export default class extends Vue {
  private valid = false;

  private name = "";
  private nameRules = [
    (v: string) => !!v || 'Name is required',
  ];

  private description = "";

  private language: any = "en-US";
  private languageOptions = [
    // { label: "German", code: "de" },
    // { label: "Greek", code: "el" },
    { label: "English (US)", code: "en-US" },
    // { label: "Spanish", code: "es" },
    // { label: "French", code: "fr" },
    // { label: "Hindi", code: "hi" },
    // { label: "Hungarian", code: "hu" },
    // { label: "Indonesian", code: "id" },
    // { label: "Italian", code: "it" },
    { label: "Japanese", code: "ja-JP" },
    // { label: "Korean", code: "ko" },
    // { label: "Norwegian", code: "nb" },
    // { label: "Dutch", code: "nl" },
    // { label: "Polish", code: "pl" },
    // { label: "Russian", code: "ru" },
    // { label: "Swedish", code: "sv" },
  ];

  private processing = false;

  createDeck() {
    this.processing = true;
    this.$store.dispatch('newDeck', {
      name: this.name,
      language: this.language
    }).then(() => {
      this.$emit('close');
    });
  }

}
