








































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import AuthManager from "@/auth/AuthManager"
import {AuthError, AuthErrorReason, User} from "@/auth/auth";

@Component
export default class extends Vue {
  private valid = false;

  private email = "";
  private emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+/.test(v) || 'E-mail must be valid',
  ];

  private emailErrorMessages = [];

  private password = "";
  private passwordRules = {
    required: (value: string) => !!value || 'Required.',
    min: (v: string | string[]) => v.length >= 8 || 'Min 8 characters',
  }

  private showPassword = false;
  private createAccount = false;

  private processing = false;

  private authError = "";

  register() {
    this.authenticate(true);
  }

  login() {
    this.authenticate(false);
  }

  get user() {
    return this.$store.getters.user();
  }

  @Watch('user')
  redirectOnLogin() {
    this.$router.push(this.$route.query.redirect || '/');
  }

  authenticate(newAccount = false) {
    if (this.valid) {
      this.processing = true;
      const auth = AuthManager.getInstance();
      auth.authenticate(this.email, this.password, newAccount).then((user: User) => {
        this.processing = false;
        this.$store.commit('setUser', user);
      }).catch((error: AuthError) => {
        this.processing = false;
        switch (error.reason) {
          case AuthErrorReason.EMAIL_EXISTS:
            this.emailErrorMessages = ["An account with this email address already exists."]
            break;
          default:
            this.authError = "There was a problem creating your account. Please try again."
            break;
        }
      });
    }
  }

  googleLogin() {
    const auth = AuthManager.getInstance();
    auth.googleLogin().then((user: User) => {
      this.$store.commit('setUser', user);
    }).catch((error: AuthError) => {
      this.processing = false;
      switch (error.reason) {
        case AuthErrorReason.EMAIL_EXISTS:
          this.emailErrorMessages = ["An account with this email address already exists."]
          break;
        default:
          this.authError = "There was a problem creating your account. Please try again."
          break;
      }
    });
  }


}
