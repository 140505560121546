import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#4d5061",
                conversation: {
                    base: "#98d7d9",
                    lighten1: "#caebec"
                }
            }
        }
    }
});
