import {Prompt} from "@/data/model/Prompt";

interface ConversationParams {
    id: string;
    title: string;
    deckId: string;
    prompts: Prompt[];
}

export class Conversation {
    id: string;
    deckId: string;
    title: string;
    prompts: Prompt[];

    constructor({id, title, prompts, deckId}: ConversationParams) {
        this.id = id;
        this.deckId = deckId
        this.title = title;
        this.prompts = prompts;
    }

    getAllSpeakers() {
        return [];
    }

    getSummary(lines = 3) {
        return this.prompts.slice(0, lines).map(p => p.text).join("\n");
    }
}
