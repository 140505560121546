export class Deck {
    id: string;
    language: string;
    name: string;

    constructor({id, language, name}: { id: string, language: string, name: string }) {
        this.id = id;
        this.language = language;
        this.name = name;
    }

    getLanguageDisplay() {
        const languageNames = new Intl.DisplayNames(['en'], {type: 'language', style: 'short'});
        return languageNames.of(this.language.split("-")[0]);
    }

    getCountry() {
        const split = this.language.split("-");
        return split[split.length - 1].toLowerCase();
    }

    generateTagMap() {
        return {};
    }
}
