import Vue from 'vue'
import Vuex from 'vuex'
import {Database, DatabaseEvents, DatabaseModels} from "@/data/database";
import {Deck} from "@/data/model/Deck";
import {Conversation} from "@/data/model/Conversation";
import {User} from "@/auth/auth";
import {FirebaseJouskaApi} from "@/api/firebase";
import {Prompt} from "@/data/model/Prompt";
import {JouskaApi} from "@/api/api";

Vue.use(Vuex)

const api: JouskaApi = new FirebaseJouskaApi();

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
    },
    decks: {},
    conversations: {},
    phrases: [],
    vocabulary: []
  },
  mutations: {
    addDeck(state, deck: Deck) {
      Vue.set(state.decks, deck.id, deck);
    },

    addConversation(state, conversation: Conversation) {
      Vue.set(state.conversations, conversation.id, conversation);
    },

    deleteDeck(state, deckId) {
      const decks = state.decks as any;
      delete decks[deckId];
    },

    deleteConversation(state, conversationId) {
      const conversations = state.conversations as any;
      delete conversations[conversationId];
    },

    setUser(state, user: User) {
      state.user = {
        loggedIn: user != null,
        data: user
      }
    }
  },
  actions: {
    init({commit, state}, db: Database) {
      db.events().on(DatabaseEvents.INSERT, ({store, data}) => {
        switch (store) {
          case DatabaseModels.DECKS:
            commit('addDeck', data);
            break;
          case DatabaseModels.CONVERSATIONS:
            commit('addConversation', data);
            break;
        }
      });

      db.events().on(DatabaseEvents.UPDATE, ({store, ...data}) => {
        console.log(store, data);
        // switch (store) {
        //   case "emails":
        //     commit('setEmails', data.emails);
        //     break;
        //   case "news":
        //     commit('setNews', data.news);
        //     break;
        //   case "messages":
        //     commit('setMessages', data.messages);
        //     break;
        // }
      });

      db.events().on(DatabaseEvents.DELETE, ({store, data}) => {
        switch (store) {
          case DatabaseModels.DECKS:
            commit('deleteDeck', data);
            break;
          case DatabaseModels.CONVERSATIONS:
            commit('deleteConversation', data);
            break;
        }
      });

      db.onInit();
    },

    async newDeck({commit, state}, data: {name: string, language: string}) {
      const {name, language} = data;
      const deck = await api.createDeck(name, language);
    },

    async newConversation({commit, state}, data: {deckId: string, title: string, prompts: Prompt[]}) {
      const {deckId, title, prompts} = data;
      const deck = await api.createConversation(deckId, title, prompts);
    },

    async deleteDeck({commit, state}, deckId: string) {
      await api.deleteDeck(deckId);
    },

    async deleteConversation({commit, state}, data: {deckId: string, conversationId: string}) {
      const {deckId, conversationId} = data;
      await api.deleteConversation(deckId, conversationId);
    }

  },
  modules: {
  },

  getters: {
    getDeck: (state) => (id: string) => {
      const decks: {[key: string]: Deck} = state.decks;
      return decks[id];
    },

    getConversationsForDeck: (state) => (deckId: string) => {
      const conversations: {[key: string]: Conversation} = state.conversations;
      return Object.values(conversations).filter((c: Conversation) => c.deckId == deckId);
    },

    getConversation: (state) => (conversationId: string) => {
      const conversations: {[key: string]: Conversation} = state.conversations;
      return conversations[conversationId];
    },

    isLoggedIn: (state) => () => {
      return state.user.loggedIn;
    },

    user: (state) => () => {
      return state.user.data
    }

  }
})
