import EventEmitter from "eventemitter3";
import firebase from "firebase/app";
import "firebase/auth";
import {Auth, AuthError, AuthErrorReason, User} from "@/auth/auth";
import {Store} from "vuex";

function userFromFirebase(firebaseUser: any) {
    if (!firebaseUser) {
        return null;
    }

    const {email, displayName: name, photoURL} = firebaseUser
    const user = new User(email, name, photoURL);
    return user;
}

export class FirebaseAuth implements Auth {

    private _events = new EventEmitter();

    static init(store: Store<any>) {
        firebase.auth().onAuthStateChanged(user => {
            store.commit("setUser", userFromFirebase(user));
        });
    }

    signOut(): Promise<void> {
        return firebase.auth().signOut();
    }

    async authenticate(email: string, password: string, newAccount: boolean) {
        if (newAccount) {
            return firebase.auth().createUserWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    return userFromFirebase(user)!;
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;

                    console.error("Error logging in", errorCode, errorMessage);
                    throw new AuthError(AuthErrorReason.EMAIL_EXISTS);
                });
        } else {
            return firebase.auth().signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    return userFromFirebase(user)!;
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error("Error logging in", errorCode, errorMessage);
                    throw new AuthError(AuthErrorReason.INVALID_CREDENTIALS);
                });
        }
    }

    googleLogin(): Promise<User> {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        firebase.auth().useDeviceLanguage();

        return firebase.auth()
            .signInWithPopup(provider)
            .then((result) => {
                return userFromFirebase(result.user)!;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error("Error logging in", errorCode, errorMessage);
                throw new AuthError(AuthErrorReason.INVALID_CREDENTIALS);
            });
    }

    events() {
        return this._events;
    }
}
