import {JouskaApi} from "@/api/api";
import {Deck} from "@/data/model/Deck";

import firebase from "firebase/app";
import "firebase/database";
import {Conversation} from "@/data/model/Conversation";
import {Prompt} from "@/data/model/Prompt";

export class FirebaseJouskaApi implements JouskaApi {
    async createDeck(name: string, language: string) {

        const database = firebase.database();

        const user = firebase.auth().currentUser;
        if (!user) {
            throw new Error("No user");
        }

        const deckRef = database.ref(`decks/${user.uid}`).push();

        const deck = new Deck({
            id: deckRef.key!,
            name,
            language
        });

        deckRef.set(deck).catch(error => {
            console.log("Error saving deck", error)
        });
        return deck;
    }

    async createConversation(deckId: string, title: string, prompts: Prompt[]): Promise<Conversation> {

        const database = firebase.database();

        const user = firebase.auth().currentUser;
        if (!user) {
            throw new Error("No user");
        }

        const conversationRef = database.ref(`conversations/${user.uid}/${deckId}`).push();

        const conversation = new Conversation({
            id: conversationRef.key!,
            deckId,
            title,
            prompts
        });

        conversationRef.set({
            id: conversationRef.key!,
            deck_id: deckId,
            title,
            prompts
        }).catch(error => {
            console.log("Error saving conversation", error)
        });

        return conversation;
    }

    async deleteDeck(deckId: string) {
        const database = firebase.database();

        const user = firebase.auth().currentUser;
        if (!user) {
            throw new Error("No user");
        }

        await database.ref(`decks/${user.uid}/${deckId}`).remove();
        await database.ref(`conversations/${user.uid}/${deckId}`).remove();
    }

    async deleteConversation(deckId: string, conversationId: string) {
        const database = firebase.database();

        const user = firebase.auth().currentUser;
        if (!user) {
            throw new Error("No user");
        }

        await database.ref(`conversations/${user.uid}/${deckId}/${conversationId}`).remove();
    }
}
