




















































































import {Component, Vue, Watch} from "vue-property-decorator";
import {FirebaseDb} from "@/data/firebase";
import 'flag-icon-css/css/flag-icon.css';
import AuthManager from "@/auth/AuthManager";

import '@/assets/css/main.css';


@Component
export default class extends Vue {

  private initialized = false;
  private showSessionView = false

  private items = [
    ['mdi-home-variant', 'Dashboard'],
    ['mdi-cog', 'Settings'],
  ];

  @Watch("$route", { immediate: true, deep: true})
  toggleSessionView(newVal: any) {
    this.showSessionView = newVal.meta?.inSession;
  }

  get user() {
    return this.$store.getters.user();
  }

  @Watch('user')
  initFirebase() {
    if (this.user && !this.initialized) {
      const firedb = new FirebaseDb();
      this.$store.dispatch('init', firedb);
      this.initialized = true;
    }
  }

  signOut() {
    AuthManager.getInstance().signOut().then(() => {
      this.$store.commit('setUser', null);
      this.$router.push("/login");
    });
  }
}
