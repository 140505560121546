import {FirebaseAuth} from "@/auth/firebase";
import {Auth} from "@/auth/auth";

export default class AuthManager {

    static instance: Auth;

    static getInstance() {
        if (!this.instance) {
            this.instance = new FirebaseAuth();
        }

        return this.instance;
    }
}
