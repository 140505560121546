import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "firebase/app";
import {FirebaseAuth} from "@/auth/firebase";

Vue.config.productionTip = false


// you must be logged in, unless it requires no auth
router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.noAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.isLoggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isLoggedIn()) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});


if (!firebase.apps.length) {
  const firebaseConfig = {
    apiKey: "AIzaSyDW-wm2rDdZj9RclVFHuZczh_RRkf2TN3E",
    authDomain: "jouska.firebaseapp.com",
    databaseURL: "https://jouska.firebaseio.com",
    projectId: "jouska",
    storageBucket: "jouska.appspot.com",
    messagingSenderId: "349886569090",
    appId: "1:349886569090:web:bb21cfefa60c89d63eb29b"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  console.debug("Firebase initialized.");
}

FirebaseAuth.init(store);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
